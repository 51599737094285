import React, { useEffect } from "react";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div">
        <div className="intro">
          <div className="overlap">
            <div className="overlap-group">
              <div className="overlap-2">
                <div className="text-wrapper">Professional Services</div>
                <div className="text-wrapper-2">Website + CMS</div>
                <div className="frame">
                  <img className="image" alt="Image" src="/img/image.png" />
                </div>
              </div>
              <div className="group">
                <div className="context">
                  <div className="text-wrapper-3">Web Design + CMS</div>
                  <p className="p">
                    Gausmann is a professional services company that offers a
                    host of professional services for home, personal care to
                    customers across Germany. We helped them design and build
                    their website and CMS keeping the end user and business
                    goals in mind while keeping the aesthetics clean minimal,
                    relevant, and user-friendly.
                  </p>
                </div>
                <div className="scope-of-work">
                  <div className="overlap-3">
                    <img className="UIX" alt="Uix" src="/img/uix.png" />
                    <div className="SM">
                      <div className="overlap-4">
                        <div className="text-wrapper-4">Content</div>
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group-2">
                            <img
                              className="line"
                              alt="Line"
                              src="/img/line-18.svg"
                            />
                            <img
                              className="img"
                              alt="Line"
                              src="/img/line-19.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-wrapper-5">Scope of Work</div>
                  </div>
                  <div className="WD">
                    <div className="overlap-5">
                      <div className="text-wrapper-6">Web Development</div>
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-2">
                          <img
                            className="line"
                            alt="Line"
                            src="/img/line-18-1.svg"
                          />
                          <img
                            className="img"
                            alt="Line"
                            src="/img/line-19-1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-wrapper">
              <div className="overlap-6">
                <div className="gaus-mann">
                  GAUS
                  <br />
                  MANN
                </div>
                <div className="text-wrapper-7">®</div>
              </div>
            </div>
          </div>
        </div>
        <img className="website" alt="Website" src="/img/website.png" />
        <div className="outro">
          <img className="img-2" alt="Element" src="/img/02.png" />
          <div className="div-wrapper">
            <div className="logo-wrapper">
              <img className="logo" alt="Logo" src="/img/logo.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
